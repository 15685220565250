import axios from "axios";
import qs from "qs";
import store from "@/store";
import i18n from "../i18n";
import router from "@/router";

// axios 配置
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://m.cargaya.es/cdb-app-api/v1/app/"
    : // ? 'https://test.chargenow.top/cdb-app-api/v1/app/'
      // : '/cdb-app-api/v2/app/'
      "/cdb-app-api/v1/app/";
axios.defaults.timeout = 10000;
axios.defaults.baseURL = baseURL;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (!config.headers.token && store.state.token) {
      config.headers.token = store.state.token;
      config.headers.lang = store.state.lang;
    } else {
      console.log("before config", JSON.stringify(config));
    }
    // if (!config.headers.agentOpenId && store.state.agentOpenId) {
    //   config.headers.agentOpenId = store.state.agentOpenId
    // }
    if (store.state.agentOpenId) {
      // SAAS 标识
      config.headers.agentOpenId = store.state.agentOpenId;
    }

    return config;
  },
  function(error) {
    console.log(0, error);
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  resp => {
    if (resp && resp.data && resp.data.code === 401) {
      alert(resp.data.msg);
      store.commit("user", null);
      store.commit("token", "");
      router.replace({
        path: `${store.state.prefix}/login`
      });
    }
    return resp;
  },
  error => {
    // console.log(JSON.stringify(error));//console : Error: Request failed with status code 402
    return Promise.reject(error);
  }
);

const get = (
  url,
  data = {},
  success = () => {},
  failure = () => {},
  config = {}
) => {
  console.log("get config", JSON.stringify(config));
  axios
    .get(url, { params: data, ...config })
    .then(resp => {
      if (resp.data.code === 0) {
        success && success(resp.data);
      } else if (resp.data.code === 403) {
        failure && failure(i18n.t("msg.roleerror"));
      } else {
        failure && failure(resp.data.msg);
      }
    })
    .catch(error => {
      if (window.Raven) {
        window.Raven.captureException(error);
      }
      console.log("get error", error);
      failure && failure(i18n.t("msg.neterror"));
    });
};

const post = (
  url,
  data = {},
  success = () => {},
  failure = () => {},
  config = {}
) => {
  if (
    !(
      config.headers &&
      config.headers["Content-Type"] !== "application/x-www-form-urlencoded"
    )
  ) {
    data = qs.stringify(data);
  }
  axios
    .post(url, data, config)
    .then(resp => {
      if (resp.data.code === 0) {
        success && success(resp.data);
      } else if (resp.data.code === 403) {
        failure && failure(i18n.t("msg.roleerror"));
      } else {
        failure && failure(resp.data.msg);
      }
    })
    .catch(error => {
      if (window.Raven) {
        window.Raven.captureException(error);
      }
      console.log("服务器出错，请重新尝试", error);
      failure && failure(i18n.t("msg.neterror"));
    });
};

export default axios;

export { get, post, baseURL };
