<template>
  <page :title="i18n.title">
    <div>
      <div class="wallet-form">
        <div class="wallet-label">{{i18n.price_label}}</div>
        <div class="wallet-input">
          <span>Ksh</span>
          <input type="number" v-model="price" :placeholder="i18n.price_placeholder" />
        </div>
      </div>
      <div class="wallet-balance" v-if="payWallet">
        {{i18n.balance}}:<span class="price">{{wallet.pCurrencySymbol}}{{wallet.pBalance}}</span>
        <span @click="onAll">{{i18n.balance_all}}</span>
      </div>
      <div class="wallet-balance" v-else>
        {{i18n.balance}}:<span class="price">Ksh{{user && user.pbalance || 0}}</span>
        <span @click="onAll">{{i18n.balance_all}}</span>
      </div>
      <div class="wallet-deposit" v-if="user && Number(user.pyajin)>0">
        <div>{{i18n.deposit}}:<span>Ksh{{user && user.pyajin || 0}}</span></div>
        <span class="tag">{{deposiTips}}</span>
      </div>
      <div v-if="checkNeedRemark" class="popup-show-modal">
        <div class="popup-show-modal-main">
          <div class="popup-show-modal-title">{{i18n.confirmtitle}}</div>
          <div class="popup-show-modal-content">{{i18n.confirmcontent}}</div>
          <div class="remarkform">
            <span>{{i18n.payee_name}}:</span>
            <input type="text" v-model.lazy="remark.name">
          </div>
          <div class="remarkform">
            <span>{{i18n.payee_bankcard}}:</span>
            <input type="text" v-model.lazy="remark.bankcard">
          </div>
          <div class="remarkform">
            <span>{{i18n.payee_phone}}:</span>
            <input type="phone" v-model.lazy="remark.phone">
          </div>
          <div class="popup-show-modal-btns">
            <div class="cancelbtn" @click="onCancel">{{i18n.cancelText}}</div>
            <div class="confirmbtn" @click="onConfirm">{{i18n.confirmText}}</div>
          </div>
        </div>
      </div>
      <div class="wallet-btn">
        <button class="btn" @click="onSubmit">{{i18n.apply}}</button>
      </div>
    </div>
  </page>
</template>

<script>
import { TX_APPLY, TX_POST, REFUND_APPLY } from '../../apis/user.js'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['user', 'browser', 'wallet']),
    i18n () {
      return this.$t('wallet_out')
    },
    deposiTips () {
      return `${this.i18n.deposit_tips}`.format(this.user)
    },
    remarkstr () {
      return `姓名:${this.remark.name},银行卡号:${this.remark.bankcard},电话:${this.remark.phone}`
    },
    payWallet () {
      return this.wallet && this.wallet.pId !== -10
    }
  },
  data () {
    return {
      price: 0,
      checkNeedRemark: false,
      NeedRemark: true,
      remark: {
        name: '',
        bankcard: '',
        phone: ''
      }
    }
  },
  methods: {
    onCancel () {
      console.log(this.remark)
      this.checkNeedRemark = !this.checkNeedRemark
      this.NeedRemark = true
      this.remark = {
        name: '',
        bankcard: '',
        phone: ''
      }
    },
    onConfirm () {
      let bool = this.remark
      if (bool.name && bool.bankcard && bool.phone) {
        this.onSubmit()
        this.checkNeedRemark = !this.checkNeedRemark
        return
      }
      this.$toast(this.i18n.msg_fail_submit)
    },
    onAll () {
      if (this.wallet && this.wallet.pId !== -10) {
        this.price = this.wallet.pBalance
      } else if (this.user && this.user.pbalance) {
        this.price = this.user.pbalance
      }
    },
    onSubmit () {
      if (isNaN(this.price) || this.price <= 0) {
        this.$toast(this.i18n.msg_fail_price)
      } else if ((this.wallet && this.wallet.pId !== -10) && (this.price > this.wallet.pBalance)) {
        this.$toast(this.i18n.msg_fail_balance)
      } else if ((this.price > this.user.pbalance) && !this.wallet) {
        this.$toast(this.i18n.msg_fail_balance)
      } else {
        this.$loading(true)
        let url = TX_APPLY
        let param = {
          amount: this.price
        }
        if (this.payWallet) {
          url = REFUND_APPLY
          param = {
            agentId: this.wallet.pDailiId,
            amount: this.price,
            refundType: 'balance',
            checkNeedRemark: this.NeedRemark // 当前提现的支付通道不支持提现为false
          }
          if (!this.NeedRemark) {
            param = {
              agentId: this.wallet.pDailiId,
              amount: this.price,
              refundType: 'balance',
              checkNeedRemark: this.NeedRemark, // 当前提现的支付通道不支持提现为false
              remark: this.remarkstr
            }
          }
        }
        if (this.browser) {
          url = `${TX_POST}/${this.price}/all`
          param = {}
        }
        this.$post(
          url,
          {
            ...param
          },
          resp => {
            this.$loading(false)
            if (this.payWallet) {
              if (resp.data && resp.data.tixianOrderId) {
                let wallet = this.wallet
                wallet.pBalance -= this.price
                this.price = 0
                this.$store.commit('wallet', wallet)
                this.$toast(this.i18n.msg_success_content)
              } else {
                this.checkNeedRemark = !this.checkNeedRemark
                this.NeedRemark = false
              }
              return
            }
            this.$toast(this.i18n.msg_success_content)
            this.$store.commit('user', {
              ...this.user,
              pbalance: this.user.pbalance - this.price
            })
            this.price = 0
            console.log(resp)
          },
          error => {
            this.$loading(false)
            this.$confirm({
              title: this.i18n.msg_title,
              content: error,
              showCancel: false,
              confirmText: this.i18n.confirm
            })
          }
        )
      }
    }
  },
  created () {
    console.log(this.wallet)
  }
}
</script>

<style lang="less">
.wallet-form{
  background: #FFFFFF;
  padding: 40px 40px 0;
}
.wallet-label{
  font-size: 32px;
  font-weight: 400;
  color: rgba(51,51,51,1);
}
.wallet-input{
  padding: 20px 0;
  display: flex;
  align-items: center;
  font-size: 56px;
  font-weight: 500;
  color: #333333;
}
.wallet-input span{
  height: 78px;
  line-height: 78px;
}
.wallet-input input{
  width: 300px;
  flex: 1;
  height: 78px;
  line-height: 78px;
  padding: 0 20px;
  color: #333333;
  font-size: 64px;
  border-radius: 20px;
  border: 1Px solid #E4E7ED;
}
.wallet-balance{
  background: #FFFFFF;
  border-top: 1px solid rgba(217,217,217,1);
  font-size: 32px;
  font-weight: 400;
  color: rgba(51,51,51,1);
  padding: 26px 40px;
  display: flex;
  align-items: center;
}
.wallet-balance span{
  font-size: 30px;
  font-weight: 400;
  color: #E5BE53;
}
.wallet-balance span.price{
  flex: 1;
  font-size: 36px;
  font-weight: 500;
  color: rgba(241,52,52,1);
}
.wallet-deposit{
  background: #FFFFFF;
  margin-top: 20px;
  padding: 26px 40px;
}
.wallet-btn{
  padding: 55px;
  button {
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
  }
}
.popup-show-modal {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  &-main {
    width: 80%;
    min-height: 220px;
    background-color: #fff;
    border-radius: 16px;
    color: #161616;
    text-align: center;
    border: 2px solid #DCDFE6;
    padding-bottom: 100px;
    position: relative;
    .remarkform{
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 0.5rem;
      span {
        width: 30%;
        text-align: start;
        font-weight: bold;
        margin: 0px 0 10px 25px;
      }
      input {
        border: solid 1px #ccc;
        border-radius: 10px;
        outline: none;
        padding: 0 10px;
        height: 40px;
        width: 60%;
      }
    }
  }
  &-title {
    font-size: 32px;
    line-height: 80px;
  }
  &-content {
    font-size: 28px;
    line-height: 40px;
    padding: 20px;
  }
  &-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    line-height: 80px;
    font-size: 28px;
    border-top: 2px solid #DCDFE6;
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    div {
      flex: 1;
    }
    div + div {
      border-left: 2px solid #DCDFE6;
    }
    .cancelbtn{
      color: #000000;
    }
    .confirmbtn{
      color: #00ce70;
    }
  }
}
</style>
